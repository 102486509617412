import { register, login } from "./helpers/skxv-id";
import React from 'react';
import axios from 'axios';
import Auth from "./routes/Auth/Auth";
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import './global.scss';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dashboard from "./routes/Dashboard/Dashboard";

function App() {
  const [user, setUser] = React.useState(null);

  const [loginUsername, setLoginUsername] = React.useState("");
  const [loginPassword, setLoginPassword] = React.useState("");

  const [registerUsername, setRegisterUsername] = React.useState("");
  const [registerPassword, setRegisterPassword] = React.useState("");
  const [registerEmail, setRegisterEmail] = React.useState("");
  const [registerPhone, setRegisterPhone] = React.useState("");
  const [registerName, setRegisterName] = React.useState("");
  const [registerSurname, setRegisterSurname] = React.useState("");

  const [file, setFile] = React.useState(null);
  
  const handleLogin = async () => {
    // const res = await login(loginUsername, loginPassword);
    // setUser(res.user);
    await login(loginUsername, loginPassword, (res) => {
      setUser(res.user);
    })
  }

  const upload = async (e) => {
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("user", user._id);
      
      console.log(user._id);

      await axios.post("http://localhost:5000/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      })
    }
  }

  return (
    <div className="App">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Router>
        <Routes>
          <Route path="/auth" element={<Auth />} />
          <Route path="/" element={
            /*<>
              <img src="https://skxvid.blob.core.windows.net/skxvid/dancing-duck-dance-duck.gif?sv=2023-01-03&st=2023-11-21T15%3A18%3A51Z&se=2029-11-22T15%3A18%3A00Z&sr=c&sp=rl&sig=0jQjFbEKaj4XFFDMiqOvBXCICk6J27XjtCPSVlSoPXM%3D" alt="" />
              {user && (
                <>
                  <h1>Welcome {user.username}</h1>
                  <img src={user.image} alt="Profile"/>
                </>
              )}
              <h1>SKXV_ID</h1>
              <h3>Login</h3>
              <input type="text" placeholder="username" onChange={(e) => setLoginUsername(e.target.value)}/>
              <input type="password" placeholder="password" onChange={(e) => setLoginPassword(e.target.value)}/>
              <button onClick={() => handleLogin()}>Login</button>
              <h3>Register</h3>
              <input type="text" placeholder="username" onChange={(e) => setRegisterUsername(e.target.value)}/>
              <input type="password" placeholder="password" onChange={(e) => setRegisterPassword(e.target.value)}/>
              <input type="text" placeholder="email" onChange={(e) => setRegisterEmail(e.target.value)}/>
              <input type="text" placeholder="phone number" onChange={(e) => setRegisterPhone(e.target.value)}/>
              <input type="text" placeholder="name" onChange={(e) => setRegisterName(e.target.value)}/>
              <input type="text" placeholder="surname" onChange={(e) => setRegisterSurname(e.target.value)}/>
              <button onClick={() => register({
                username: registerUsername,
                password: registerPassword,
                email: registerEmail,
                phone: registerPhone,
                name: registerName,
                surname: registerSurname,
              })}>Register</button>
              <h3>Upload</h3>
              <input type="file" onChange={(e) => setFile(e.target.files[0])}/>
              <button onClick={() => upload()}>Upload</button>
            </>*/
            <Dashboard />
          } />
        </Routes>
      </Router>
      </div>
  );
}

export default App;
