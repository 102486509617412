import axios from "axios";
const url = "https://skxv-id-production.up.railway.app";

export const login = async (username, password, remember, callback) => {
    if (!username ||!password) {
        console.error("Please provide a username and password.")
        return;
    }
    await axios.post(`${url}/login`, {
        username: username,
        password: password,
        remember: remember,
    }).then((res) => {
        localStorage.setItem("user", JSON.stringify(res.data.user));
        callback({message: res.data.message, user: res.data.user, status: res.status, error: false, token: res.data.token})
    }).catch((err) => {
        callback({message: err.message, status: err.status, error: true})
    })
}

export const register = async (details, callback) => {
    if (!details.username || !details.password || !details.email) {
        console.error("Please provide a username, password, and email.")
    }
    await axios.post(`${url}/register`, {
        username: details.username,
        password: details.password,
        email: details.email,
        phone: details.phone,
        image: details.image,
        name: details.name,
        surname: details.surname,
    }).then((res) => {
        callback({message: res.data.message, user: res.data.user, status: res.status, error: false})
    }).catch((err) => {
        callback({message: err.message, status: err.status, error: true})
    })
}