import React from "react";
import useQuery from "../../helpers/useQuery";
import { login, register as Register } from "../../helpers/skxv-id";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import "./Auth.scss";

// {query.get("redirect")}
const Auth = () => {
  const query = useQuery();
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [register, setRegister] = React.useState(false);
  const [remember, setRemember] = React.useState(false);

  const [registerName, setRegisterName] = React.useState("");
  const [registerSurname, setRegisterSurname] = React.useState("");
  const [registerUsername, setRegisterUsername] = React.useState("");
  const [registerPassword, setRegisterPassword] = React.useState("");
  const [registerPasswordConfirm, setRegisterPasswordConfirm] =
    React.useState("");
  const [registerEmail, setRegisterEmail] = React.useState("");
  const { search } = useLocation();

  const handleLogin = async () => {
    if (!username || !password) {
      toast.error("Please fill in all fields.");
      return;
    }

    await login(username, password, remember, (res) => {
      if (res.error) {
        toast.error("Please check your credentials and try again.");
        console.error(res);
        return;
      }

      window.location.href = `${query.get("redirect")}/?token=${res.token}`;
    });
  };

  const handleRegister = async () => {
    if (
      !registerName ||
      !registerSurname ||
      !registerUsername ||
      !registerPassword ||
      !registerPasswordConfirm ||
      !registerEmail
    ) {
      toast.error("Please fill in all fields.");
      return;
    }

    if (registerPassword !== registerPasswordConfirm) {
      toast.error("Passwords do not match.");
      return;
    }

    await Register(
      {
        username: registerUsername,
        password: registerPassword,
        name: registerName,
        surname: registerSurname,
        email: registerEmail,
        phone: "",
      },
      (res) => {
        if (res.error) {
          toast.error("An error occurred. Please check console for details.");
          console.error(res);
        }

        window.location.href = `${window.location.href.replace(
          "&register=true",
          ""
        )}&success=true`;
      }
    );
  };

  React.useEffect(() => {
    if (!query.get("redirect")) {
      window.location.href = "https://skxv.dev";
    }

    if (query.get("register") === "true") {
      setRegister(true);
    }

    if (query.get("success") === "true") {
      toast.success("Registration successful! You can now login.");
    }
  }, []);

  return (
    <div className="Auth">
      <div className="wrapper">
        <h1>{!register ? "Log in using SKXV ID" : "Register SKXV ID"}</h1>
        <div className="form">
          {!register ? (
            <>
              <input
                type="text"
                placeholder="username"
                onChange={(e) => setUsername(e.target.value)}
              />
              <input
                type="password"
                placeholder="password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <button onClick={() => handleLogin()}>Login</button>
              <div className="options">
                <div className="remember">
                  <input
                    type="checkbox"
                    checked={remember}
                    onClick={() => setRemember(!remember)}
                  />
                  <span>Remember me</span>
                </div>
                <a href="#">Forgot your password?</a>
              </div>
            </>
          ) : (
            <>
              <input
                type="text"
                placeholder="Name"
                onChange={(e) => setRegisterName(e.target.value)}
              />
              <input
                type="text"
                placeholder="Surname"
                onChange={(e) => setRegisterSurname(e.target.value)}
              />
              <input
                type="text"
                placeholder="Username"
                onChange={(e) => setRegisterUsername(e.target.value)}
              />
              <input
                type="password"
                placeholder="Password"
                onChange={(e) => setRegisterPassword(e.target.value)}
              />
              <input
                type="password"
                placeholder="Confirm Password"
                onChange={(e) => setRegisterPasswordConfirm(e.target.value)}
              />
              <input
                type="email"
                placeholder="Email"
                onChange={(e) => setRegisterEmail(e.target.value)}
              />
              <button
                onClick={() => {
                  handleRegister();
                }}
              >
                Register
              </button>
            </>
          )}
        </div>
        <p className="seperator">OR</p>
        {!register ? (
          <button
            onClick={() =>
              (window.location.href = `${window.location.href}&register=true`)
            }
          >
            Register new ID
          </button>
        ) : (
          <button
            onClick={() => {
              window.location.href = window.location.href.replace(
                "&register=true",
                ""
              );
            }}
          >
            Login with existing ID
          </button>
        )}
      </div>
    </div>
  );
};

export default Auth;
