import React from "react";
import axios from "axios";
import useQuery from "../../helpers/useQuery";
import { toast } from "react-toastify";
import { Pencil } from "tabler-icons-react";

import "./Dashboard.scss";

const Dashboard = () => {
  const query = useQuery();
  const filePicker = React.useRef(null);
  const [user, setUser] = React.useState(null);
  const [error, setError] = React.useState(false);

  const [username, setUsername] = React.useState("");
  const [name, setName] = React.useState("");
  const [surname, setSurname] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");

  const [oldPassword, setOldPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");

  const handleFileChange = async (e) => {
    toast("Uploading profile picture...");
    const file = e.target.files[0];
    const form = new FormData();
    form.append("file", file);
    form.append("user", user._id);

    await axios
      .post("https://skxv-id-production.up.railway.app/picture", form, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setUser(res.data.user);
        toast.success("Profile picture updated successfully!");
      })
      .catch((err) => {
        setError(true);
        toast.error(
          "An error occurred while updating your profile picture. Check console for details."
        );
        console.error(err);
      });
  };

  const saveDetails = async () => {
    toast("Updating account details...");
    await axios
      .post(
        "https://skxv-id-production.up.railway.app/user",
        {
          username,
          name,
          surname,
          email,
          phone,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setUser(res.data.user);
        toast.success("Account details updated successfully!");
      })
      .catch((err) => {
        setError(true);
        toast.error(
          "An error occurred while updating your account details. Check console for detials."
        );
        console.error(err);
      });
  };

  const changePassword = async () => {
    toast("Uploading password...");
    await axios
      .post(
        "https://skxv-id-production.up.railway.app/password",
        {
          oldPassword,
          newPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setUser(res.data.user);
        toast.success("Password updated successfully!");
        setOldPassword("");
        setNewPassword("");
      })
      .catch((err) => {
        setError(true);
        toast.error(
          "An error occurred while updating your password. Check console for detials."
        );
        console.error(err);
      });
  };

  const getUser = async (token) => {
    await axios
      .get("https://skxv-id-production.up.railway.app/user", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setUser(res.data.user);
        toast.success(`Welcome back, ${res.data.user.name}!`);
      })
      .catch((err) => {
        setError(true);
        toast.error("An error occurred while fetching your user data.");
        localStorage.removeItem("token");
      });
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    window.location.href = "https://skxv.dev";
  };

  React.useEffect(() => {
    const token = query.get("token");

    if (token) {
      localStorage.setItem("token", token);
      getUser(token);
      return;
    }

    if (localStorage.getItem("token")) {
      getUser(localStorage.getItem("token"));
      return;
    }

    window.location.href =
      "https://id.skxv.dev/auth?redirect=http://id.skxv.dev";
  }, []);

  React.useEffect(() => {
    if (user) {
      setUsername(user.username);
      setName(user.name);
      setSurname(user.surname);
      setEmail(user.email);
      setPhone(user.phone);
    }
  }, [user]);
  return (
    <div className="Dashboard">
      {user && !error && (
        <div className="Dashboard">
          <div className="wrapper">
            <h1>Dashboard</h1>
            <div className="setting picture">
              <h3>Profile picture</h3>
              <button
                className="image"
                onClick={() => filePicker.current.click()}
              >
                <img src={user.image} alt="Profile" />
                <Pencil size={30} />
              </button>
              <input
                type="file"
                onChange={(e) => handleFileChange(e)}
                ref={filePicker}
                style={{ display: "none" }}
              />
            </div>
            <div className="setting">
              <h3>Acount details</h3>
              <p className="label">Username</p>
              <input
                type="text"
                placeholder="Enter a username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <p className="label">Name</p>
              <input
                type="text"
                placeholder="Enter your name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <p className="label">Surname</p>
              <input
                type="text"
                placeholder="Enter your surname"
                value={surname}
                onChange={(e) => setSurname(e.target.value)}
              />
              <p className="label">Email</p>
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <p className="label">Phone number</p>
              <input
                type="text"
                placeholder="Enter your phone number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
              <button onClick={() => saveDetails()}>Save account</button>
            </div>
            <div className="setting">
              <h3>Password</h3>
              <input
                type="password"
                placeholder="Enter your current password"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
              />
              <input
                type="password"
                placeholder="Enter your new password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <button onClick={() => changePassword()}>Change password</button>
            </div>
            <div className="setting">
              <button className="danger" onClick={() => handleLogout()}>
                Log out
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
